import themeDefault, { Theme } from "./themeDefault.js";

const themeDark: Theme = {
  ...themeDefault,
  boxShadow: "rgba(255, 255, 255, 0.15) 0px 20px 10px",
  borderStyle: "2px solid #bfbfbf",
  gameGridLineThickness: "0.1em",
  colors: {
    ...themeDefault.colors,
    hero: "hsl(200, 80%, 15%)",
    heroComplementary: "hsl(27, 73%, 25%)",
    surfacePrimary: "hsl(200, 100%, 5%)",
    surfaceSecondary: "hsl(201, 53%, 16%)",
    surfaceTertiary: "hsl(231, 60%, 5%)",
    containerPrimary: "hsl(200, 0%, 20%)",
    containerSecondary: "hsl(200, 100%, 20%)",
    containerTertiary: "hsl(250, 73%, 35%)",
    containerSuccess: "hsl(229, 70%, 25%)",
    containerWarning: "hsl(50, 70%, 25%)",
    containerDanger: "hsl(0, 60%, 25%)",
    surfacePrimaryComp: "hsl(27, 73%, 34%)",
    onSurfacePrimary: "hsl(200 20% 80%)",
    onSurfaceSecondary: "#e6e6e6",
    onSurfaceTertiary: "#bfbfbf",
    onContainerPrimary: "#bfbfbf",
    onContainerSecondary: "#e6e6e6",
    onContainerTertiary: "#FAFAFA",
    onContainerSuccess: "#e6e6e6",
    onContainerWarning: "#e6e6e6",
    onContainerDanger: "#e6e6e6",
    shipFill: "rgb(107 134 148 / 80%)",
    shipStroke: "rgb(195 207 214 / 70%)",
    shipBorderSuccess: "rgb(110 204 51 / 100%)",
    shipBorderError: "rgb(223 32 32 / 100%)",
    hitCell: "hsl(27 60% 50% / 70%)",
    hitCellHighlight: "hsl(50 100% 50% / 80%)",
    missedCell: "hsl(211 50% 40% / 80%)",
    sunkShipHighlight: "rgb(255 213 0 / 80%)",
    sunkShipFill: "rgb(191 64 64 / 60%)",
    sunkShipStroke: "rgb(54 70 78 / 80%)",
    scoreGreen: "#1B5E20",
    scoreRed: "#BF360C",
  },
};

export default themeDark;
